<template>
  <!-- 基本信息容器 -->
  <div class="baseInfoContainer" v-if="activityName">
    <div class="baseInfoTitle ws_oneLine">
      {{ activityName || "" }}
    </div>
    <div class="baseInfoList">
      <div class="baseInfoItem" v-if="reportType == 2">
        <span>报告类型：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.reportType || "" }}</div>
      </div>
      <div class="baseInfoItem" v-if="evaluator.name">
        <span>姓名：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.name || "" }}</div>
      </div>
      <div class="baseInfoItem" v-if="evaluator.schoolName && evaluator.studentNo">
        <span>学号：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.studentNo || "" }}</div>
      </div>
      <div class="baseInfoItem" v-if="(evaluator.schoolName === undefined || evaluator.schoolName === null || evaluator.schoolName === '') && evaluator.studentNo">
        <span>ID：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.studentNo || "" }}</div>
      </div>
      <div class="baseInfoItem" v-if="evaluator.gender">
        <span>性别：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.gender || "" }}</div>
      </div>
      <div class="baseInfoItem" v-if="evaluator.age">
        <span>年龄：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.age || "" }}</div>
      </div>
      <div class="baseInfoItem" v-if="evaluator.schoolName">
        <span>学校：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.schoolName || "" }}</div>
      </div>
      <div class="baseInfoItem"
        v-if="(evaluator.reportType == '年级报告' || evaluator.reportType == '班级报告') && evaluator.gradeName">
        <span>年级：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.gradeName || "" }}</div>
      </div>
      <div class="baseInfoItem" v-if="evaluator.reportType == '班级报告' && evaluator.className">
        <span>班级：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.className || "" }}</div>
      </div>
      <div class="baseInfoItem" v-if="reportType == 2">
        <span>测评人数：</span>
        <div class="baseConetent ws_oneLine">{{ evaluator.evaluateNum || "" }}</div>
      </div>
    </div>
    <el-divider>测评时间</el-divider>
    <div class="baseInfoList">
      <div class="baseInfoItem">
        <span>测评日期：</span>
        <div class="baseConetent ws_oneLine">{{ timer.evaluateDate || "" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
//reportType 1:个体  2:团体
export default {
  props: ["myDataObj", "activityName", "evaluator", "timer", "reportType", "isHiddenBtn", "isExport"],
  data() {
    return {};
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/variables.scss";
/* 语法  @media screen and (max-width:768px) and (min-width:320px){}*/
@media screen and (max-width: 768px) {

  /* 1.在超小屏设备的时候 768px以下   当前容器的宽度100%   并且背景设置为蓝色 */
  .baseInfoContainer {
    .baseInfoTitle {
      text-align: left;
    }

    .baseInfoList {
      display: flex;
      justify-content: left !important;

      .baseInfoItem {
        width: 50%;
      }
    }

    .baseInfoBtnList {
      display: none !important;
    }
  }
}

// 基本信息容器
.baseInfoContainer {
  position: relative;
  margin-bottom: 0.3rem;

  .baseInfoTitle {
    padding: 0.28rem 0.2rem 0.28rem;
    color: $ws-text-color-black;
    text-align: center;
    font-weight: bold;
    font-family: '宋体';
    font-size: 16px;
  }

  .baseInfoList {
    padding: 0rem 0.2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .baseInfoItem {
      height: 0.6rem;
      display: flex;
      align-items: center;

      // padding-right:0.4rem;
      // border: 1px solid pink;
      span {
        font-size: 12px;
        margin-left: 0.2rem;
        color: rgb(55, 150, 243);
      }

      .baseConetent {
        font-size: 12px;
        // padding: 0.28rem 0.2rem 0.2rem;
        // padding: 0.28rem 0.2rem 0.2rem 0.05rem;
        color: $ws-text-color-black;
      }

      &:last-child {
        padding-right: 0rem;
      }
    }
  }

  .baseInfoBtnList {
    position: absolute;
    display: flex;
    top: 0.2rem;
    right: 0.2rem;

    .exportReportBtn {
      cursor: pointer;
      height: 0.4rem;
      line-height: 0.4rem;
      width: 1.2rem;
      font-size: 0.24rem;
      color: #fff;
      margin-right: 0.2rem;
      border-radius: 0.12rem;
      text-align: center;
      background-color: $ws-mainColor;
    }

    .exportPaperBtn {
      cursor: pointer;
      height: 0.4rem;
      line-height: 0.4rem;
      width: 1.2rem;
      font-size: 0.24rem;
      color: #fff;
      border-radius: 0.12rem;
      text-align: center;
      background-color: #02c0fe;
    }
  }
}
</style>
