<!-- 团体需要修改样式 -->
<template>
  <div id="containerId" class="container" v-if="myDataObj">
    <!-- 封面 -->
    <img v-if="myDataObj && myDataObj.cover" :src="myDataObj.cover" class="coverImg" />

    <!-- 基本信息容器 -->
    <PersonalContainer :myDataObj="myDataObj" :activityName="myDataObj.activityName" :evaluator="myDataObj.evaluator"
      :timer="myDataObj.timer" :reportType="2" :isHiddenBtn="isHiddenBtn" :isExport="isExport" />

    <!-- 工具介绍(项目) -->
    <div class="dimensionResultContainer" v-if="myDataObj.introduction">
      <TitleBar :title="myDataObj.introduction.title" />
      <ContentBox :content="myDataObj.introduction.content" :isBackGroundColor="true" />
    </div>

    <!-- 样本分析 -->
    <div class="itemResultContainer">
      <el-divider class="dividerONE">
        <h1>样本分析</h1>
      </el-divider>
      <!-- 整体分析 -->
      <div v-if="myDataObj.totalCompleteRatio">
        <TitleBar title="整体分析" />
        <div class="itemBox">
          <!--
          <div class="subTitle">
            <p>本次测评 {{ myDataObj.evaluator.schoolName }} 测评人数统计：应测: {{
              myDataObj.sampleAnalysis.overallSampleAnalysis.peopleCounting.partakeNum }} 人, 实测: {{
                myDataObj.sampleAnalysis.overallSampleAnalysis.peopleCounting.completeNum }} 人, 未测: {{
                myDataObj.sampleAnalysis.overallSampleAnalysis.peopleCounting.unCompleteNum }} 人</p>
          </div>
          -->
          <div class="tableType">
            <el-table border :data="myDataObj.totalCompleteRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" label="统计范围">
                <template>整体</template>
              </el-table-column>
              <el-table-column align="center" prop="partakeNum" label="应测" />
              <el-table-column align="center" prop="completeNum" label="实测" />
              <el-table-column align="center" label="完成率">
                <template slot-scope="scope">{{ scope.row.completeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 部门分析 -->
      <div v-if="myDataObj.deptCompleteRatio">
        <TitleBar title="部门分析" />
        <div class="itemBox">
          <div class="tableType">
            <el-table border :data="myDataObj.deptCompleteRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" prop="schoolName" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="应测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.partakeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="completeNum" label="实测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.completeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" label="完成率">
                <template slot-scope="scope">{{ scope.row.completeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 学校分析 -->
      <div v-if="myDataObj.schoolCompleteRatio">
        <TitleBar title="学校分析" />
        <div class="itemBox">
          <div class="tableType">
            <el-table border :data="myDataObj.schoolCompleteRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" prop="schoolName" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="应测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.partakeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="completeNum" label="实测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.completeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" label="完成率">
                <template slot-scope="scope">{{ scope.row.completeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 年级分析 -->
      <div v-if="myDataObj.gradeCompleteRatio">
        <TitleBar title="年级分析" />
        <div class="itemBox">
          <div class="tableType">
            <el-table border :data="myDataObj.gradeCompleteRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" prop="gradeName" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="应测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.partakeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="completeNum" label="实测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.completeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" label="完成率">
                <template slot-scope="scope">{{ scope.row.completeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 班级分析 -->
      <div v-if="myDataObj.classCompleteRatio">
        <TitleBar title="班级分析" />
        <div class="itemBox">
          <div class="tableType">
            <el-table border :data="myDataObj.classCompleteRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" prop="className" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="应测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.partakeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="completeNum" label="实测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.completeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" label="完成率">
                <template slot-scope="scope">{{ scope.row.completeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 性别分析 -->
      <div v-if="myDataObj.genderCompleteRatio">
        <TitleBar title="性别分析" />
        <div class="itemBox">
          <div class="tableType">
            <el-table border :data="myDataObj.genderCompleteRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" prop="gender" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="应测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.partakeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="completeNum" label="实测" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.completeRatio.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" label="完成率">
                <template slot-scope="scope">{{ scope.row.completeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- 预警分析 -->
    <div class="itemResultContainer">
      <el-divider class="dividerONE">
        <h1>预警分析</h1>
      </el-divider>
      <!-- 整体预警分析 -->
      <div v-if="myDataObj.totalWarnRatio">
        <TitleBar title="整体分析" />
        <div class="itemBox">
          <div class="tableType">
            <el-table border :data="myDataObj.totalWarnRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" label="统计范围">
                <template>整体</template>
              </el-table-column>
              <el-table-column align="center" prop="partakeNum" label="参与人数" />
              <el-table-column align="center" prop="oneCount" label="一级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.oneRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="twoCount" label="二级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.twoRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="threeCount" label="三级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.threeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 部门分析 -->
      <div v-if="myDataObj.deptWarnRatio">
        <TitleBar title="部门分析" />
        <div class="itemBox">
          <!-- 统计图 -->
          <!-- <div class="chartsDisplayBox">
            <div class="dimensionChartView">
              <Charts :option="myDataObj.warningAnalysis.schoolAnalysis.charts.chartData.option" />
            </div>
          </div> -->
          <!-- 统计表格 -->
          <div class="tableType">
            <el-table border :data="myDataObj.deptWarnRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" prop="schoolName" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="参与人数" />
              <el-table-column align="center" prop="oneCount" label="一级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.oneRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="twoCount" label="二级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.twoRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="threeCount" label="三级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.threeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 学校分析 -->
      <div v-if="myDataObj.schoolWarnRatio">
        <TitleBar title="学校分析" />
        <div class="itemBox">
          <!-- 统计图 -->
          <!-- <div class="chartsDisplayBox">
            <div class="dimensionChartView">
              <Charts :option="myDataObj.warningAnalysis.schoolAnalysis.charts.chartData.option" />
            </div>
          </div> -->
          <!-- 统计表格 -->
          <div class="tableType">
            <el-table border :data="myDataObj.schoolWarnRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" prop="schoolName" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="参与人数" />
              <el-table-column align="center" prop="oneCount" label="一级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.oneRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="twoCount" label="二级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.twoRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="threeCount" label="三级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.threeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 年级分析 -->
      <div v-if="myDataObj.gradeWarnRatio">
        <TitleBar title="年级分析" />
        <div class="itemBox">
          <!-- <div class="chartsDisplayBox">
            <div class="dimensionChartView">
              <Charts :option="myDataObj.warningAnalysis.gradeAnalysis.charts.chartData.option" />
            </div>
          </div> -->
          <div class="tableType">
            <el-table border :data="myDataObj.gradeWarnRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6',
              color: '#606266',
            }">
              <el-table-column align="center" prop="gradeName" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="参与人数" />
              <el-table-column align="center" prop="oneCount" label="一级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.oneRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="twoCount" label="二级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.twoRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="threeCount" label="三级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.threeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 班级分析 -->
      <div v-if="myDataObj.classWarnRatio">
        <TitleBar title="班级分析" />
        <div class="itemBox">
          <!-- <div class="chartsDisplayBox">
            <div class="dimensionChartView">
              <Charts :option="myDataObj.warningAnalysis.classAnalysis.charts.chartData.option" />
            </div>
          </div> -->
          <div class="tableType">
            <el-table border :data="myDataObj.classWarnRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6', color: '#606266',
            }">
              <el-table-column align="center" prop="className" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="参与人数" />
              <el-table-column align="center" prop="oneCount" label="一级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.oneRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="twoCount" label="二级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.twoRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="threeCount" label="三级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.threeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 性别分析 -->
      <div v-if="myDataObj.genderWarnRatio">
        <TitleBar title="性别分析" />
        <div class="itemBox">
          <!-- <div class="chartsDisplayBox">
            <div class="dimensionChartView">
              <Charts :option="myDataObj.warningAnalysis.genderAnalysis.charts.chartData.option" />
            </div>
          </div> -->
          <div class="tableType">
            <el-table border :data="myDataObj.genderWarnRatio" style="width: 100%" :header-cell-style="{
              background: '#eef1f6', color: '#606266',
            }">
              <el-table-column align="center" prop="gender" label="统计范围" />
              <el-table-column align="center" prop="partakeNum" label="参与人数" />
              <el-table-column align="center" prop="oneCount" label="一级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.oneRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="twoCount" label="二级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.twoRate.toFixed(2) + '%' }}</template>
              </el-table-column>
              <el-table-column align="center" prop="threeCount" label="三级预警" />
              <el-table-column align="center" label="占比">
                <template slot-scope="scope">{{ scope.row.threeRate.toFixed(2) + '%' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- 测评详情 -->
    <div class="itemResultContainer" v-if="myDataObj.papers">
      <el-divider class="dividerONE">
        <h1>测评详情</h1>
      </el-divider>
      <div v-if="myDataObj.papers.length > 0">
        <div v-for="(item, index) in myDataObj.papers" :key="index">
          <!-- 量表名称 -->
          <TitleBar :title="item.name" />
          <!-- 工具介绍 -->
          <TitleBar :title="item.introduction.title" />
          <ContentBox :content="item.introduction.content" :isBackGroundColor="true" />
          <!-- 整体分析 -->
          <div v-if="item.totalResult">
            <TitleBar title="整体分析" />
            <div class="itemBox">
              <ContentBox :content="item.totalResult.introduction" :isBackGroundColor="true" />
            </div>
            <div class="itemBox" v-if="item.totalResult.totalScaleRatioTable">
              <div class="subTitle">
                <p>整体统计：</p>
              </div>
              <div class="chartsDisplayBox">
                <div class="dimensionChartView">
                  <Charts :option="item.totalResult.totalScaleRatioChart.option" />
                </div>
              </div>
              <div class="tableType">
                <CustomizeElTable :tableHeader="item.totalResult.totalScaleRatioTable.header"
                  :tableBody="item.totalResult.totalScaleRatioTable.body" />
              </div>
            </div>

            <div class="itemBox" v-if="item.totalResult.schoolScaleRatioTable">
              <div class="subTitle">
                <p>学校分析：</p>
              </div>
              <div class="chartsDisplayBox">
                <div class="dimensionChartView">
                  <Charts :option="item.totalResult.schoolScaleRatioChart.option" />
                </div>
              </div>
              <div class="tableType">
                <CustomizeElTable :tableHeader="item.totalResult.schoolScaleRatioTable.header"
                  :tableBody="item.totalResult.schoolScaleRatioTable.body" />
              </div>
            </div>

            <div class="itemBox" v-if="item.totalResult.gradeScaleRatioTable">
              <div class="subTitle">
                <p>年级分析：</p>
              </div>
              <div class="chartsDisplayBox">
                <div class="dimensionChartView">
                  <Charts :option="item.totalResult.gradeScaleRatioChart.option" />
                </div>
              </div>
              <div class="tableType">
                <CustomizeElTable :tableHeader="item.totalResult.gradeScaleRatioTable.header"
                  :tableBody="item.totalResult.gradeScaleRatioTable.body" />
              </div>
            </div>

            <div class="itemBox" v-if="item.totalResult.classScaleRatioTable">
              <div class="subTitle">
                <p>班级分析：</p>
              </div>
              <div class="chartsDisplayBox">
                <div class="dimensionChartView">
                  <Charts :option="item.totalResult.classScaleRatioChart.option" />
                </div>
              </div>
              <div class="tableType">
                <CustomizeElTable :tableHeader="item.totalResult.classScaleRatioTable.header"
                  :tableBody="item.totalResult.classScaleRatioTable.body" />
              </div>
            </div>

            <div class="itemBox" v-if="item.totalResult.genderScaleRatioTable">
              <div class="subTitle">
                <p>性别分析：</p>
              </div>
              <div class="chartsDisplayBox">
                <div class="dimensionChartView">
                  <Charts :option="item.totalResult.genderScaleRatioChart.option" />
                </div>
              </div>
              <div class="tableType">
                <CustomizeElTable :tableHeader="item.totalResult.genderScaleRatioTable.header"
                  :tableBody="item.totalResult.genderScaleRatioTable.body" />
              </div>
            </div>

            <div class="itemBox">
              <div class="subTitle">
                <p>结论：{{ item.totalResult.conclusion }}</p>
              </div>
            </div>

            <div class="itemBox">
              <ContentBox :content="item.totalResult.myExplain" :isBackGroundColor="true" />
            </div>
          </div>
          <!-- 各维度测评结果 -->
          <div v-if="item.detailResult && item.detailResult.length > 0">
            <TitleBar title="各维度测评结果" />
            <div v-for="(detailItem, index) in item.detailResult" :key="index">
              <TitleBar :title="detailItem.genderScaleRatioTable.body[0].row1"
                v-if="detailItem.genderScaleRatioTable" />
              <div class="itemBox">
                <ContentBox :content="detailItem.introduction" :isBackGroundColor="true" />
              </div>
              <div class="itemBox" v-if="detailItem.totalScaleRatioTable">
                <div class="subTitle">
                  <p>整体统计：</p>
                </div>
                <div class="chartsDisplayBox">
                  <div class="dimensionChartView">
                    <Charts :option="detailItem.totalScaleRatioChart.option" />
                  </div>
                </div>
                <div class="tableType">
                  <CustomizeElTable :tableHeader="detailItem.totalScaleRatioTable.header"
                    :tableBody="detailItem.totalScaleRatioTable.body" />
                </div>
              </div>

              <div class="itemBox" v-if="detailItem.schoolScaleRatioTable">
                <div class="subTitle">
                  <p>学校分析：</p>
                </div>
                <div class="chartsDisplayBox">
                  <div class="dimensionChartView">
                    <Charts :option="detailItem.schoolScaleRatioChart.option" />
                  </div>
                </div>
                <div class="tableType">
                  <CustomizeElTable :tableHeader="detailItem.schoolScaleRatioTable.header"
                    :tableBody="detailItem.schoolScaleRatioTable.body" />
                </div>
              </div>

              <div class="itemBox" v-if="detailItem.gradeScaleRatioTable">
                <div class="subTitle">
                  <p>年级分析：</p>
                </div>
                <div class="chartsDisplayBox">
                  <div class="dimensionChartView">
                    <Charts :option="detailItem.gradeScaleRatioChart.option" />
                  </div>
                </div>
                <div class="tableType">
                  <CustomizeElTable :tableHeader="detailItem.gradeScaleRatioTable.header"
                    :tableBody="detailItem.gradeScaleRatioTable.body" />
                </div>
              </div>

              <div class="itemBox" v-if="detailItem.classScaleRatioTable">
                <div class="subTitle">
                  <p>班级分析：</p>
                </div>
                <div class="chartsDisplayBox">
                  <div class="dimensionChartView">
                    <Charts :option="detailItem.classScaleRatioChart.option" />
                  </div>
                </div>
                <div class="tableType">
                  <CustomizeElTable :tableHeader="detailItem.classScaleRatioTable.header"
                    :tableBody="detailItem.classScaleRatioTable.body" />
                </div>
              </div>

              <div class="itemBox" v-if="detailItem.genderScaleRatioTable">
                <div class="subTitle">
                  <p>性别分析：</p>
                </div>
                <div class="chartsDisplayBox">
                  <div class="dimensionChartView">
                    <Charts :option="detailItem.genderScaleRatioChart.option" />
                  </div>
                </div>
                <div class="tableType">
                  <CustomizeElTable :tableHeader="detailItem.genderScaleRatioTable.header"
                    :tableBody="detailItem.genderScaleRatioTable.body" />
                </div>
              </div>

              <div class="itemBox">
                <div class="subTitle">
                  <p>结论：{{ detailItem.conclusion }}</p>
                </div>
              </div>

              <div class="itemBox">
                <ContentBox :content="detailItem.myExplain" :isBackGroundColor="true" />
              </div>
            </div>
          </div>
          <!-- 总体建议 -->
          <TitleBar :title="item.summary.title" />
          <ContentBox :content="item.summary.content" :isBackGroundColor="true" />
        </div>
      </div>
    </div>

    <!-- 总体建议（项目） -->
    <div class="dimensionResultContainer" v-if="myDataObj.advice">
      <TitleBar :title="myDataObj.advice.title" />
      <ContentBox :content="myDataObj.advice.content" :isBackGroundColor="true" />
    </div>

    <!-- 封底 -->
    <img v-if="myDataObj && myDataObj.backCover" :src="myDataObj.backCover" class="coverImg" />

  </div>
</template>

<script>
import htmlToPdf from "@/utils/htmlToPdf";
import PersonalContainer from "@/components/personalContainer";
import CustomizeElTable from "@/components/customizeElTable";
import { mixins } from "@/utils/mixins";
import { getGroupReport } from "@/api/report";
export default {
  mixins: [mixins],
  name: "GroupReport",
  components: {
    PersonalContainer,
    CustomizeElTable
  },
  data() {
    return {
      wsHeartImg: require("../../assets/images/caseReportNew/list_heart.png"), //心图片
      myDataObj: undefined, //个案网络请求回来的数据
      unitRectDivList: [
        {
          type: 0,
          ratio: 1,
          deepWidth: 0,
        },
        {
          type: 1,
          ratio: 1,
          deepWidth: 0,
        },
        {
          type: 2,
          ratio: 0.5,
          deepWidth: 0,
        },
        {
          type: 3,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 4,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 5,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 6,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 7,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 8,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 9,
          ratio: 0,
          deepWidth: 0,
        },
      ],
      jtData: {

      }
    };
  },
  methods: {
    //导出报告按钮点击事件
    ws_exportReportBtnClickAction() {
      //导出PDF
      // htmlToPdf.downloadPDF(document.querySelector("#containerId"), "我的PDF");
      htmlToPdf.printOut(document.querySelector("#containerId"));
    },
    //计算分数
    ws_getReportScoreActionWithScore(reportScore, reportTotalScore) {
      reportScore = reportScore || 0;
      reportTotalScore = reportTotalScore || 0.01;
      reportScore = (reportScore / reportTotalScore) * 100;

      //   var wsCount =  parseInt(reportScore /10);
      var wsCount = Math.floor(reportScore / 10);
      var wsRemainder = reportScore % 10;
      wsCount = wsRemainder > 0 ? wsCount + 1 : wsCount;
      var tempList = this.unitRectDivList;
      for (var i = 0; i < tempList.length; i++) {
        var tempDic = tempList[i];
        if (i <= wsCount - 1) {
          tempDic.ratio = 1;
          tempDic.deepWidth = 11;
          if (wsRemainder > 0 && i == wsCount - 1) {
            tempDic.ratio = wsRemainder / 10;
            tempDic.deepWidth = (11 * wsRemainder) / 10;
          }
        } else {
          tempDic.ratio = 0;
          tempDic.deepWidth = 0;
        }
      }
      this.unitRectDivList = tempList;
    },
    //渲染echarts事件
    ws_drawEchartsAction() {
      this.myDataObj.papers.forEach((item, index) => {
        var wsOption = item.totalResult.totalScaleRatioChart.option;
        let idStr = "total_scale_chart" + index;
        let charts = this.$echarts.init(document.getElementById(idStr));
        charts.setOption(wsOption);
      });
    },
    //个案数据网络请求
    ws_geCaseReportDataNetWorkAction() {
      var that = this;
      getGroupReport(that.queryParams).then((res) => {
        //成功
        if (res.code == 200) {
          that.myDataObj = res.data;

          // 遍历 that.myDataObj.papers 并为子项添加id属性
          that.myDataObj.papers.forEach((item, index) => {
            item.totalResult.totalScaleRatioChart.chartId = `total_scale_chart${index}`;
            item.totalResult.schoolScaleRatioChart.chartId = `school_scale_chart${index}`;
            item.totalResult.gradeScaleRatioChart.chartId = `grade_scale_chart${index}`;
            item.totalResult.classScaleRatioChart.chartId = `class_scale_chart${index}`;
            item.totalResult.genderScaleRatioChart.chartId = `gender_scale_chart${index}`;
            item.detailResult.forEach((detailItem, detailIndex) => {
              detailItem.totalScaleRatioChart.chartId = `detail${index}_total_scale_chart${detailIndex}`;
              detailItem.schoolScaleRatioChart.chartId = `detail${index}_school_scale_chart${detailIndex}`;
              detailItem.gradeScaleRatioChart.chartId = `detail${index}_grade_scale_chart${detailIndex}`;
              detailItem.classScaleRatioChart.chartId = `detail${index}_class_scale_chart${detailIndex}`;
              detailItem.genderScaleRatioChart.chartId = `detail${index}_gender_scale_chart${detailIndex}`;
            })
          });

          //渲染echart图
          that.$nextTick(() => {
            that.ws_drawEchartsAction();
          });
        } else {
          // uni.showToast({
          // 	title: res.statusCode != 200 ? res.statusCode : res.data.msg,
          // 	icon: 'none'
          // });
        }
      });
    },
  },
  mounted() {
    //个案数据网络请求
    this.ws_geCaseReportDataNetWorkAction();
    //实现自适应部分
    window.onresize = () => {
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/commonPage.scss";

.dividerONE {
  width: 100%;
  height: 2px;
  background-color: #5656d4;
  margin-top: 60px;
}

.dividerONE h1 {
  font-size: 15px;
  //letter-spacing: 2px;
  color: $ws-mainColor;
  font-weight: bold;
  line-height: 45px;
  font-family: '宋体';
  white-space: nowrap;
}

.el-divider__text.is-center {
  background-color: #ebeff8;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.subTitle p {
  margin-left: 40px;
  line-height: 50px;
}
</style>
