<template>
  <div>
    <el-table :data="tableBody" style="width: 100%">
      <el-table-column align="center" :prop="table.key" :label="table.label" v-for="(table, tableIndex) in tableHeader"
        :key="tableIndex">
      </el-table-column>
    </el-table>
    <div class="tableFoot"></div>
  </div>
</template>

<script>
export default {
  name: "customElTable",
  props: ["tableHeader", "tableBody"],
  data() {
    return {
      myDataObj: undefined,
      chartsTable: {
        tableHeader: [
          { "columnName": "分值", "propName": "colName" },
          { "columnName": "0-1分", "propName": "colOne" },
          { "columnName": "0.3-0.5分", "propName": "colTwo" },
          { "columnName": "0.5-0.8分", "propName": "colThree" },
          { "columnName": "0.8-1.2分", "propName": "colFour" },
          { "columnName": "1.2-2分", "propName": "colFive" },
        ],
        tableData: [
          { "colName": "儿科症状", "colOne": "无风险", "colTwo": "轻微风险", "colThree": "一般风险", "colFour": "中度风险", "colFive": "预警风险" },
          { "colName": "人数", "colOne": "124", "colTwo": "101", "colThree": "102", "colFour": "28", "colFive": "1" },
          { "colName": "分布", "colOne": "34.83%", "colTwo": "28.37%", "colThree": "28.65%", "colFour": "7.87%", "colFive": "0.28%" },
        ]
      },
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #FFF;
}

::v-deep .el-table th {
  background-color: #fff;
  border-top: 2px solid black;
  padding: 5px 0px;
}

::v-deep .el-table__header-wrapper {
  overflow: hidden;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

::v-deep .el-table::before {
  border-bottom: 4px solid black;
  height: 2px;
}

::v-deep .el-table td {
  padding: 0px 0px;
}

::v-deep .el-table thead {
  color: #000;
  letter-spacing: 2px;
}

.tableFoot {
  /*border: 1px solid red;*/
  display: inline-block;
  width: 100%;
  height: 20px;
}
</style>